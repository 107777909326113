export const stations = [
    {
        id: 0,
        name: 'SLAM!',
        slug: 'slam',
        audioSource: 'https://stream.slam.nl',
        videoSource: null,
        apiUrl: 'https://api.slam.nl/api/live?brand=slam',
    },
    {
        id: 1,
        name: 'Qmusic',
        slug: 'qmusic',
        audioSource: 'https://stream.qmusic.nl/qmusic/aachigh',
        videoSource: null,
        apiUrl: 'https://qnl-play-fetch.s3.amazonaws.com/tracks.json',
    },
    {
        id: 2,
        name: '538',
        slug: 'radio538',
        audioSource: 'http://playerservices.streamtheworld.com/api/livestream-redirect/RADIO538AAC.aac',
        videoSource: null,
        apiUrl: 'https://api.slam.nl/api/live?brand=slam',
    },
];

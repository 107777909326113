import { useState } from 'react';

import Header from './components/Header';
import AudioPlayer from './components/AudioPlayer';

import { stations } from './stations';

function App() {
    // const stationList =;

    const [selectedStation, setSelectedStation] = useState({});

    function selectStation(station) {
        setSelectedStation(station);
    }

    return (
        <div className="App">
            <Header />
            <div className="container">
                <div className="station-list">
                    {stations.map((station, index) => (
                        <div key={index} className="station">
                            <h1 className="station-name">{station.name}</h1>
                            <div>
                                <button className="play-button">VIDEO</button>
                                <button onClick={() => selectStation(station)} className="play-button">
                                    AUDIO
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <AudioPlayer station={selectedStation} className="player" />
            </div>
        </div>
    );
}

export default App;

import React, { useEffect, useRef, useState } from 'react';

import { getNowPlaying } from '../nowPlaying';

export default function AudioPlayer({ station }) {
    const audioRef = useRef();

    const [nowPlaying, setNowPlaying] = useState({
        title: '',
        artist: '',
    });

    function setMetaData() {
        getNowPlaying(station).then((data) => {
            setNowPlaying(data);
            navigator.mediaSession.metadata = new MediaMetadata({
                title: data.title,
                artist: data.artist,
            });
        });
    }

    useEffect(() => {
        audioRef.current.pause();
        audioRef.current.load();
        audioRef.current.play();

        setMetaData();
    }, [station]);

    setInterval(() => {
        setMetaData();
    }, 60000);

    return (
        <div className="audio-player-holder">
            <div className="player">
                <h1 className="player-station">{station.name}</h1>
                <audio className="audio-player" ref={audioRef} controls>
                    <source src={station.audioSource} />
                </audio>
                <div className="meta-data">
                    <h2 className="track-title">{nowPlaying.title}</h2>
                    <h3 className="track-artist">{nowPlaying.artist}</h3>
                </div>
            </div>
        </div>
    );
}

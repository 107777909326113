export async function getNowPlaying(station) {
    const apiResponse = await stationApiRequest(station.apiUrl);

    if (station.slug == 'slam') {
        return {
            title: apiResponse.data.song.title,
            artist: apiResponse.data.song.artist,
        };
    }

    if (station.slug == 'qmusic') {
        return {
            title: apiResponse.title,
            artist: apiResponse.artist,
        };
    }

    return {
        title: '',
        artist: '',
    };
}

async function stationApiRequest(apiUrl) {
    const response = await fetch(apiUrl);
    const json = await response.json();
    return json;
}
